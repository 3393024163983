import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw"; // markdownにvideo タグを埋め込むために必要

import useLang from '../hooks/useLang';
import { PageLayout } from "../components/page-layout";
import styles from './quick-start-page.module.css';

export const QuickStartPage: React.FC = () => {
  const [markdown, setMarkdown] = useState('');
  const lang = useLang();
  const title = lang === 'ja' ? 'Neh クイックスタート' : 'Neh Quick Start';

  useEffect(() => {
    fetch(`/quick-start.${lang}.md`)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, [lang]); // langを依存配列に追加することで、言語が変更されたときに再度fetchを実行する。

  return (
    <PageLayout>
      <div className="auth0-features">
        <h1 className="neh-features__title">
          {title}
        </h1>

        <div className="content-layout">
          <div className={`content__body ${styles.markdown}`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{markdown}</ReactMarkdown>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
