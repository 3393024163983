import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import useLang from '../hooks/useLang';
import { PageLayout } from "../components/page-layout";
import styles from './terms-page.module.css';

export const TermsPage: React.FC = () => {
  const [markdown, setMarkdown] = useState('');
  const lang = useLang();

  const title = 'Neh User Terms of Service';

  useEffect(() => {
    fetch(`/terms.${lang}.md`)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <PageLayout>
      <div className="auth0-features">
        <h1 className="neh-features__title">
          {title}
        </h1>

        <div className="content-layout">
          <div className={`content__body ${styles.markdown}`}>
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
